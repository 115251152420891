import styled from 'styled-components'
import { moveInLeft } from 'styles/animation'
import BackgroundImage from 'gatsby-background-image'

const BackgroundContainer = styled(BackgroundImage)`
  padding: 60px 20px 0 20px;
  width: 100%;
  height: 460px;
  margin-top: 50px;
`

const FirstSectionWrapper = styled.div`
  ${moveInLeft('.8s', 'ease-out')}
  padding: 0 20px;

  @media (max-width: 720px) {
    height: 100%;
  }

  & h1 {
    font: normal normal 600 100px/20px Poppins;
    font-weight: semibold;
    letter-spacing: 0px;
    font-size: 40px;
    line-height: 40px;
    color: #147efb;
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    padding: 10px 25px;
    text-align: center;
    width: fit-content;
    margin: 0 auto 5px auto;

    @media (min-width: 720px) {
      text-align: flex-start;
      margin: 0 0 5px 0;
    }
  }

  & p {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #147efb;
    padding: 20px;
    margin: 0;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;

    @media (min-width: 720px) {
      max-width: 390px;
      text-align: left;
      margin: 0;
    }
  }
`

const FooterWrapper = styled.div`
  padding: 40px 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .go-back-button {
    border: 4px solid #147efb;
    background: #147efb;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 1.8rem;
    color: #ffffff;
    font-size: 1.6rem;
    height: 40px;
    min-width: 140px;
    margin-right: 1rem;
    &:hover {
      border: 4px solid #147efb;
      background: #147efb;
      color: white;
    }

    &.hide {
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
  }

  @media (max-width: 720px) {
    justify-content: center;
  }
`

export { BackgroundContainer, FirstSectionWrapper, FooterWrapper }
